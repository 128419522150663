import './style.css'
import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import Stats from 'three/examples/jsm/libs/stats.module.js'
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js'
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { Octree } from 'three/examples/jsm/math/Octree.js';
import { OctreeHelper } from './OctreeHelper.js';
import { Capsule } from 'three/examples/jsm/math/Capsule.js';
//import { gsap } from 'gsap'



if (/Android|iPhone|ipad/i.test(navigator.userAgent)) {
    const mouseMobile = document.querySelector('.mouse')
    mouseMobile.style.opacity = '0.0'

    const wasdMobile = document.querySelector('.wasd')
    wasdMobile.style.opacity = '0.0'

    const clickMobile = document.querySelector('.click')
    clickMobile.style.opacity = '0.0'

    const releaseMobile = document.querySelector('.release')
    releaseMobile.style.opacity = '0.0'

    const blackNo = document.querySelector('.black')
    blackNo.classList.add('blackEnded')
    blackNo.style.opacity = '0.0'
// This checks if the current device is in fact mobile
console.log("cell & tablet")




var video = document.getElementById("myVideo");


  }


  else if(navigator.userAgent.indexOf('Mac OS X') != -1){

    const mouseMac = document.querySelector('.mouse')
    mouseMac.style.opacity = '0.0'

    const wasdMac = document.querySelector('.wasd')
    wasdMac.style.opacity = '0.0'

    const clickMac = document.querySelector('.click')
    clickMac.style.opacity = '0.0'

    const releaseMac = document.querySelector('.release')
    releaseMac.style.opacity = '0.0'

    const blackNo = document.querySelector('.black')
    blackNo.classList.add('blackEnded')
    blackNo.style.opacity = '0.0'
// This checks if the current device is in fact mobile
console.log("Mac")




var video = document.getElementById("myVideo");


}

  else{
    const textPC = document.querySelector('.content')
    
    textPC.style.opacity = '0.0'
    console.log("PC")

        /**
     * Loaders 
     */

    //loading bar
    const loadingBarElement = document.querySelector('.loading-bar')
    const blackElement = document.querySelector('.black')
    const loadingManager = new THREE.LoadingManager(
        // Loaded
        () =>
        {
            // Wait a little
            window.setTimeout(() =>
            {
                // Animate overlay
                blackElement.classList.add('blackEnded')
                blackElement.style.opacity = '0.0'
                
                //gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

                // Update loadingBarElement
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''
            
            }, 500)
        },

        // Progress
        (itemUrl, itemsLoaded, itemsTotal) =>
        {
            // Calculate the progress and update the loadingBarElement
            const progressRatio = itemsLoaded / itemsTotal
            loadingBarElement.style.transform = `scaleX(${progressRatio})`
        }
    )

    //Draco Loader
    const dracoLoader = new DRACOLoader(loadingManager);
    dracoLoader.setDecoderPath('/draco/')

    //GLTF Loader
    const loader = new GLTFLoader(loadingManager);
    const gltfLoader = new GLTFLoader(loadingManager)

    loader.setDRACOLoader(dracoLoader)


    /**
     * Base
     */
    /**
     * Stats
     */
    //const stats = new Stats();
    //stats.showPanel(0)
    //document.body.appendChild( stats.dom );


    /**
     * Base
     */
    // Debug
    const gui = new GUI({
        width: 400
        
    })
    gui.hide()
    // Canvas
    const canvas = document.querySelector('canvas.webgl')

    // Scene
    const scene = new THREE.Scene();

    /**
     * Overlay
     */
    /*
    const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
    const overlayMaterial = new THREE.ShaderMaterial({
        // wireframe: true,
        transparent: true,
        uniforms:
        {
            uAlpha: { value: 1 }
        },
        vertexShader: `
            void main()
            {
                gl_Position = vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            uniform float uAlpha;
    
            void main()
            {
                gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
            }
        `
    })
    const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
    scene.add(overlay)
    */
    /**
     * Textures
     */


    /**
     * Materials
     */
    //glass
    const params = {
        color: 0xffffff,
                    transmission: 1,
                    opacity: 1,
                    metalness: 0,
                    roughness: 0,
                    ior: 1.5,
                    thickness: 0.01,
                    specularIntensity: 1,
                    specularColor: 0xffffff,
                    envMapIntensity: 1,
                    lightIntensity: 1,
                    exposure: 1
        
    };
    const materialGlass = new THREE.MeshPhysicalMaterial( {
        color: params.color,
        metalness: params.metalness,
        roughness: params.roughness,
        ior: params.ior,
        envMapIntensity: params.envMapIntensity,
        transmission: params.transmission, // use material.transmission for glass materials
        specularIntensity: params.specularIntensity,
        specularColor: params.specularColor,
        opacity: params.opacity,
        side: THREE.DoubleSide,
        transparent: true
    } );




    const floorMaterial = new THREE.MeshStandardMaterial( {
        color: 0xc2c2c2,
            roughness: 0.05,
        metalness: 1
    } );

    const wallMaterial = new THREE.MeshStandardMaterial( {
        color: 0xc2c2c2,
            roughness: 0.05,
        metalness: 1
    } );

    /**
     * Models
     */

    //constrains models
    function onLoadConstrainGeometry( gltf ) {
        worldOctree.fromGraphNode( gltf.scene );
        const helper = new OctreeHelper( worldOctree );
        helper.visible = false;
        scene.add( helper );
        //scene.add( gltf.scene );
    }

    //3d models
    function onLoad( gltf ) {
        gltf.scene.traverse( child => {
            if ( child.isMesh ) {
                child.castShadow = true;
                child.receiveShadow = true;
            
            }
        })
        scene.add( gltf.scene );
    }

    //GLS
    function onLoadGlass( gltf ) {
        gltf.scene.traverse( child => {
            if ( child.isMesh ) {
                child.castShadow = false;
                child.receiveShadow = false;
                child.material = materialGlass
            }
        })
        scene.add( gltf.scene );
    }


    gltfLoader.load(
        './models/gltf/severa011.glb',
        (gltf) =>
        {
            scene.add(gltf.scene)

            // Get each object
        // const floor = gltf.scene.children.find((child) => child.name === 'floor_StaticMeshComponent0')
            //const wall = gltf.scene.children.find((child) => child.name === 'wall_StaticMeshComponent0')
            

            
        //  const model = gltf.scene
        // console.log(model)
            /*
            const portalLightMesh = gltf.scene.children.find((child) => child.name === 'portalLight')
            const poleLightAMesh = gltf.scene.children.find((child) => child.name === 'poleLightA')
            const poleLightBMesh = gltf.scene.children.find((child) => child.name === 'poleLightB')
    */
            // Apply materials
        //   floor.material = floorMaterial
        // wall.material = wallMaterial
        /*
            portalLightMesh.material = portalLightMaterial
            poleLightAMesh.material = poleLightMaterial
            poleLightBMesh.material = poleLightMaterial*/
        }
    )

    gltfLoader.load(
        './models/gltf/severa011art01.glb',
        (gltf) =>
        {
            scene.add(gltf.scene)

            }
    )

    gltfLoader.load(
        './models/gltf/severa011art02.glb',
        (gltf) =>
        {
            scene.add(gltf.scene)

            }
    )

    loader.load( './models/gltf/sevraconstrain.glb', onLoadConstrainGeometry) 
    //loader.load( './models/gltf/severa008.glb', onLoad) 
    //loader.load( './models/gltf/severa008gls.glb', onLoadGlass)
    

    /**
     * Lights
     */



    //ambient
    scene.background = new THREE.Color( 0x000000 );
    //scene.fog = new THREE.Fog( 0x808080, 0, 85 );

    const ambientLight = new THREE.AmbientLight(0xffffff, 1.0)
    gui.add(ambientLight, 'intensity').min(0).max(3).step(0.001)
    scene.add(ambientLight)

    const rectLight = new THREE.RectAreaLight( 0xffffff, 1,  1, 1 );
    rectLight.position.set( 1, 1, 0 );
    rectLight.lookAt( 1, 1, 0 );
    //scene.add( rectLight )
    //scene.add( new RectAreaLightHelper( rectLight ) );



    /**
     * Helpers
     */
    //const directionalLightHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
    //scene.add(directionalLightHelper)

    /**s
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }
    window.addEventListener('resize', () =>
    {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })
    window.addEventListener('orientationchange', () =>
    {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })


    //Full screen
    document.getElementById('Layer_1').addEventListener('click', function (event) {
        toggleFullScreen();  
    
    }, false);




    function toggleFullScreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            document.documentElement.webkitRequestFullScreen();
            document.documentElement.mozRequestFullScreen();
            
        
        } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            document.mozCancelFullScreen(); 
            document.webkitCancelFullScreen(); 
            document.cancelFullScreen(); 
        }
        }
    }


    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.1, 100 );
    camera.rotation.order = 'YXZ';
    camera.rotation.y = Math.PI * -0.5 ;




    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({antialias: true,
        canvas: canvas
    })
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.0;
    container.appendChild( renderer.domElement );
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    /**
     * Post processing
     */
    let RenderTargetClass = null

    if(renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2)
    {
        RenderTargetClass = THREE.WebGLMultisampleRenderTarget
        console.log('Using WebGLMultisampleRenderTarget')
    }
    else
    {
        RenderTargetClass = THREE.WebGLRenderTarget
        console.log('Using WebGLRenderTarget')
    }
    
    const renderTarget = new RenderTargetClass(
        800,
        600,
        {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat
        }
    )


    // Effect composer
    const effectComposer = new EffectComposer(renderer, renderTarget)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    effectComposer.setSize(sizes.width, sizes.height)

    // Render pass
    const renderPass = new RenderPass(scene, camera)
    effectComposer.addPass(renderPass)

    
    
    // RGB Shift pass
    const rgbShiftPass = new ShaderPass(RGBShiftShader)
    rgbShiftPass.enabled = false
    rgbShiftPass.uniforms.amount = 0 
    effectComposer.addPass(rgbShiftPass)
    /*
    uniforms: {

        'tDiffuse': { value: null },
        'amount': { value: 0.005 },
        'angle': { value: 0.0 }

    }*/

    // Gamma correction pass
    const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader)
    effectComposer.addPass(gammaCorrectionPass)

    // Antialias pass
    if(renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2)
    {
        const smaaPass = new SMAAPass()
        effectComposer.addPass(smaaPass)

        console.log('Using SMAA')
    }



    // Tin pass
    const TintShader = {
        uniforms:
        {
            tDiffuse: { value: null },
            uTint: { value: null }
        },
        vertexShader: `
            varying vec2 vUv;

            void main()
            {
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

                vUv = uv;
            }
        `,
        fragmentShader: `
            uniform sampler2D tDiffuse;
            uniform vec3 uTint;

            varying vec2 vUv;

            void main()
            {
                vec4 color = texture2D(tDiffuse, vUv);
                color.rgb += uTint;

                gl_FragColor = color;
            }
        `
    }

    const tintPass = new ShaderPass(TintShader)
    tintPass.material.uniforms.uTint.value = new THREE.Vector3()
    effectComposer.addPass(tintPass)


    // SAO
    let saoPass = new SAOPass( scene, camera, false, true );
    effectComposer.addPass( saoPass );


    /**
     * Constrain Geometry 
     */
    //elements
    const GRAVITY = 30;
    const worldOctree = new Octree();
    const playerCollider = new Capsule( new THREE.Vector3( 0, 0.35, 0 ), new THREE.Vector3( 0, 1.45, 0 ), .35 );
    const playerVelocity = new THREE.Vector3();
    const playerDirection = new THREE.Vector3();
    let playerOnFloor = false;
    let mouseTime = 0;
    const keyStates = {};
    const vector1 = new THREE.Vector3();
    const vector2 = new THREE.Vector3();
    const vector3 = new THREE.Vector3();

    // Activate keyboard & mouse
    document.addEventListener( 'keydown', ( event ) => {
        keyStates[ event.code ] = true;
    } );
    document.addEventListener( 'keyup', ( event ) => {
        keyStates[ event.code ] = false;
    } );


    const clickElement = document.querySelector('.click')
    const releaseElement = document.querySelector('.release')
         
 
    window.addEventListener('resize', (evt) => { 
        if (window.innerHeight == screen.height) {
            clickElement.classList.add('ended')
        releaseElement.classList.add('started')
            console.log('FULL SCREEN');
        } else {
            clickElement.classList.add('ended')
  releaseElement.classList.add('ended')
            console.log('NORMAL SCREEN');
        }
    });


    container.addEventListener( 'mousedown', () => {
        document.body.requestPointerLock();
        toggleFullScreen();
        
        mouseTime = performance.now();
        //clickElement.classList.add('ended')
        //releaseElement.classList.add('started')
        
    } );


    document.body.addEventListener( 'mousemove', ( event ) => {
        if ( document.pointerLockElement === document.body ) {
            camera.rotation.y -= event.movementX / 1700;
            camera.rotation.x -= event.movementY / 3000;
        }
    } );

    // colisones
    function playerCollisions() {
        const result = worldOctree.capsuleIntersect( playerCollider );
        playerOnFloor = false;
        if ( result ) {
            playerOnFloor = result.normal.y > 0;
            if ( ! playerOnFloor ) {
                playerVelocity.addScaledVector( result.normal, - result.normal.dot( playerVelocity ) );
            }
            playerCollider.translate( result.normal.multiplyScalar( result.depth ) );
        }
    }

    //player
    function updatePlayer( deltaTime ) {
        let damping = Math.exp( - 3.5 * deltaTime ) - 1;
        if ( ! playerOnFloor ) {
            playerVelocity.y -= GRAVITY * deltaTime;
            damping *= 0.1;
        }
        playerVelocity.addScaledVector( playerVelocity, damping );

        const deltaPosition = playerVelocity.clone().multiplyScalar( deltaTime );
        playerCollider.translate( deltaPosition );

        playerCollisions();

        camera.position.copy( playerCollider.end );
    }
    function getForwardVector() {
        camera.getWorldDirection( playerDirection );
        playerDirection.y = 0;
        playerDirection.normalize();
        return playerDirection;
    }
    function getSideVector() {
        camera.getWorldDirection( playerDirection );
        playerDirection.y = 0;
        playerDirection.normalize();
        playerDirection.cross( camera.up );
        return playerDirection;
    }
    function controls( deltaTime ) {
        // gives a bit of air control
        const speedDelta = deltaTime * ( playerOnFloor ? 12 : 8 );
        if ( keyStates[ 'KeyW' ] ) {
            playerVelocity.add( getForwardVector().multiplyScalar( speedDelta ) );
        }
        if ( keyStates[ 'KeyS' ] ) {
            playerVelocity.add( getForwardVector().multiplyScalar( - speedDelta ) );
        }
        if ( keyStates[ 'KeyA' ] ) {
            playerVelocity.add( getSideVector().multiplyScalar( - speedDelta ) );
        }
        if ( keyStates[ 'KeyD' ] ) {
            playerVelocity.add( getSideVector().multiplyScalar( speedDelta ) );
        }
        if ( playerOnFloor ) {
            if ( keyStates[ 'Space' ] ) {
                playerVelocity.y = 8;
            }
        }
    }
    function teleportPlayerIfOob() {
        if ( camera.position.y <= - 5 ) {
            playerCollider.start.set( 0, 0.35, 0 );
            playerCollider.end.set( 0, 1.45, 0 );
            playerCollider.radius = 0.35;
            camera.position.copy( playerCollider.end );
            //camera.rotation.set( 0, 179, 0 );
        }
    }

    /**
     * Gui
     */   
    //AO
    
    gui.add( saoPass.params, 'output', {
        'Beauty': SAOPass.OUTPUT.Beauty,
        'Beauty+SAO': SAOPass.OUTPUT.Default,
        'SAO': SAOPass.OUTPUT.SAO,
        'Depth': SAOPass.OUTPUT.Depth,
        'Normal': SAOPass.OUTPUT.Normal
    } ).onChange( function ( value ) {
        saoPass.params.output = parseInt( value );
    } );
    saoPass.params.saoIntensity  = 0.001
    saoPass.params.saoScale = 0.5
    saoPass.params.saoKernelRadius = 20
    saoPass.params.saoBlurRadius = 6
    saoPass.params.saoBlurStdDev = 16
    //gui.add( saoPass.params, 'saoBias', - 1, 1 );
    gui.add( saoPass.params, 'saoIntensity', 0, 0.01 );
    gui.add( saoPass.params, 'saoScale', 0.1, 1 );
    gui.add( saoPass.params, 'saoKernelRadius', 2, 50 );
    //gui.add( saoPass.params, 'saoMinResolution', 0, 1 );
    //gui.add( saoPass.params, 'saoBlur' );
    gui.add( saoPass.params, 'saoBlurRadius', 1, 20 );
    gui.add( saoPass.params, 'saoBlurStdDev', 0.5, 150 );
    //gui.add( saoPass.params, 'saoBlurDepthCutoff', 0.0, 0.1 );



    //Tint
    gui.add(tintPass.material.uniforms.uTint.value, 'x').min(- 0.02).max(0.02).step(0.001).name('red')
    gui.add(tintPass.material.uniforms.uTint.value, 'y').min(- 0.02).max(0.02).step(0.001).name('green')
    gui.add(tintPass.material.uniforms.uTint.value, 'z').min(- 0.02).max(0.02).step(0.001).name('blue')



    /**
     * Animate
     */
    const STEPS_PER_FRAME = 5;
    const clock = new THREE.Clock();
    //let previousTime = 0
    function tick() {

    //stats.begin()

        /*
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = (elapsedTime - previousTime) / STEPS_PER_FRAME
        previousTime = elapsedTime
        */
        const deltaTime = Math.min( 0.05, clock.getDelta() ) / STEPS_PER_FRAME;

        // we look for collisions in substeps to mitigate the risk of
        // an object traversing another too quickly for detection.

        for ( let i = 0; i < STEPS_PER_FRAME; i ++ ) {
            controls( deltaTime );
            updatePlayer( deltaTime );
            teleportPlayerIfOob();
        }
        //renderer.render( scene, camera );
        effectComposer.render()
        window.requestAnimationFrame( tick );

    
    // stats.end()
    }
    tick();



}
